.wcm-emed-home {

    h1, h2, h3, h4, h5, h6 {
        color: $wcm-med-gray;
        margin-bottom: 20px;
    }

    .twitter-timeline {
        width: 100vw !important;
    }
    .hero-section {
        padding-left: 0;
        padding-right: 0;
        .hero-image--full {
            width: 100%;
            height: 175px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin: 0 0 20px;

            @include breakpoint($sm) {
                height: 300px;
            }
        }
    }

    .header-section {
        text-align: center;

        font-size: 20px;
        color: $wcm-med-gray;
    }

    .header-callouts {
        margin-top: 20px;
        margin-bottom: 30px;
        .col-sm-4 {
            text-align: center;
            // padding-left: 15px;
            // padding-right: 15px;
            a {
                -webkit-box-shadow: 0px 4px 8px 0px rgba(221,221,221,1);
                -moz-box-shadow: 0px 4px 8px 0px rgba(221,221,221,1);
                box-shadow: 0px 4px 8px 0px rgba(221,221,221,1);
                display: block;
                border-bottom: none;
                padding: 25px 10px 20px;
                font-weight: bold;
            }
            .cta-icon {
                transition: all 0.3s ease 0s;
                display: block;
                color: $wcm-dark-orange;
                fill: $wcm-bright-orange;
                text-align: center;
                margin: 0 auto 10px auto;
                width: 40px;
                height: 50px;

                &.cta-icon-education {
                    width: 50px;
                    height: 50px;
                }
            }

            &:hover {
                .cta-icon {
                    transform: scale(1.2);
                }
            }
        }
    } // header callouts

    .top-section {
        // background-color: $wcm-bg-gray;
        position: relative;
        // background: rgb(247,247,247);
        background: $wcm-bg-gray;
        @include breakpoint($sm) {
            background: linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(247,247,247,1) 50%, rgba(0,0,0,1) 51%, rgba(0,0,0,1) 100%);
        }

        // &:after {
        //     background: black;
        //     position: absolute;
        //     width: 50%;
        //     height: 100%;
        //     left: 50%;
        // }

        .top-section-bg-right {
            // background-color: $wcm-red;
            // background-blend-mode: multiply;
            // mix-blend-mode: multiply;
            display: none;
            background-image: url(../images/em-banner.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: 0;
            top: 50%;
            width: 100%;
            height: 50%;

            @include breakpoint($sm) {
                left: 50%;
                top: 0;
                width: 50%;
                height: 100%;
                display: block;
            }

            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $wcm-red;
                // background-blend-mode: multiply;
                // mix-blend-mode: multiply;
                opacity: 0.8;
            }

            @supports (mix-blend-mode: multiply) {
                &:after {
                    mix-blend-mode: multiply;
                    opacity: 1;
                }
            }
        }

        .col-sm-6 {
            padding-top: 20px;
            padding-bottom: 20px;

            &:first-child {
                .pane-bundle-text {
                    padding-right: 20px;
                }
            }

            &:last-child {
                .pane-bundle-text {
                    @include breakpoint($sm) {
                        padding-left: 20px;
                    }
                }
                @include breakpoint($xs) {
                    background-image: url(../images/em-banner.jpg);
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &:after {
                    @include breakpoint($xs) {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        background-color: $wcm-red;
                        // mix-blend-mode: multiply;
                        opacity: 0.8;
                    }
                }
                @supports (mix-blend-mode: multiply) {
                    &:after {
                        @include breakpoint($xs) {
                            mix-blend-mode: multiply;
                            opacity: 1;
                        }
                    }
                }
                @include breakpoint($xs) {
                    .pane-bundle-text {
                        position: relative;
                        z-index: 1;
                    }
                }
                color: $wcm-white;

                h2, h3 {
                    color: $wcm-white;
                }
                a {
                    color: $wcm-white;
                }
            }
        }
    } // top section

    .top-bottom-section {
        background-color: $wcm-bg-gray;
        position: relative;

        @include breakpoint($xs) {
            padding-left: 0;
            padding-right: 0;
        }

        .top-bottom-section-bg-left {
            // background-color: $wcm-red;
            // background-blend-mode: multiply;
            // mix-blend-mode: multiply;
            background-image: url(/sites/default/files/general/em-banner.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            left: 0%;
            top: 0;
            width: 50%;
            height: 100%;

            /*&:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background-color: $wcm-red;
                // background-blend-mode: multiply;
                mix-blend-mode: multiply;
            }*/
        }

        .col-sm-6 {
            padding-top: 40px;
            padding-bottom: 40px;

            &:last-child {
                .pane-bundle-text {
                    @include breakpoint($sm) {
                        padding-left: 20px;
                    }
                }
            }
        }

        .bg-container {
            position: relative;
            height: 100%;
            width: 100%;
            @include breakpoint($sm) {
                // position: absolute;
            }

            @include breakpoint($xs) {
                margin: 0;
            }

            .content-left {
                height: 100%;
                padding-left: 0;
                padding-right: 0;
                padding-top: 0;
                padding-bottom: 0;

                div {
                    height: 100%;
                }
            }

            .hero-image--full {
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: top center;
            }
        }

        .bottom-content-right {
            margin-left: 0;
            margin-right: 0;
        }

        .content-right {
            display: none;
        }

        .bottom-right {
            .col-sm-6 {
                &:first-child {
                    @include breakpoint($xs) {
                        display: none;
                    }
                }
            }
        }
    } // top bottom section

    .middle-top {

        padding-top: 40px;
        padding-bottom: 40px;

        div[class*="col-sm"] {
            @include breakpoint($xs) {
                // padding-top: 40px;
                padding-bottom: 20px;
            }
        }

        .col-sm-8 {
            .pane-bundle-text {
                @include breakpoint($sm) {
                    border-left: 1px solid $wcm-border-gray;
                    padding-left: 40px;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                }
            }
        }
    } // middle bottom

    .middle-bottom {
        background: $wcm-bg-gray;

        div[class*="col-sm"] {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .btn--wcm {
            display: inline-block;
        }
    } // middle bottom

    .content-news-events {
        padding-top: 30px;
        .news-events-header {
            margin-bottom: 30px;
            h2 {
                text-align: center;
            }
            a {
                float: right;
            }
        }
    } // news & events

    .bottom-callouts {
        padding-top: 40px;
        padding-bottom: 40px;
        background-image: url(../images/caretaker.jpg);
        background-repeat: no-repeat;
        background-color: rgba(0, 0, 0, 0.7);
        background-size: cover;
        position: relative;
        // background-blend-mode: multiply;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            top: 0;
            left: 0;
            display: block;
        }

        @supports (mix-blend-mode: multiply) {
            background-blend-mode: multiply;

            &:before {
                display: none;
            }
        }

        color: $wcm-white;
        h2, h3, h4, h5, h6, a {
            color: $wcm-white;
        }

        .col-sm-4 {
            @include breakpoint($xs) {
                padding-bottom: 10px;
            }
        }
        .col-sm-3 {
            .pane-bundle-image:first-child {
                @include breakpoint($sm) {
                    padding-bottom: 20px;
                }

            }
        }
    }

    #footer-callouts {
      background: $wcm-bg-gray;
      text-align: center;
      margin-top: 40px;
      padding: 20px;

      .pane-bundle-text {
        max-width: 600px;
        margin: 0 auto;
      }

      p a {
          padding: 5px 0px 5px 35px;
          // &:before {
          //     content: '';
          //     background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/give.svg);
          //     width: 25px;
          //     height: 25px;
          //     background-repeat: no-repeat;
          //     background-size: 25px 25px;
          //     background-position: left center;
          // }
          background-image: url(/profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/SVG/give.svg);
          background-repeat: no-repeat;
          background-size: 25px 25px;
          background-position: left center;
        }
    } // footer callouts
}

.front {
    footer {
        padding-top: 0;
    }
}

.front:not(.panels-ipe-editing) {
    .wcm-emed-home .top-bottom-section .bg-container {
        @include breakpoint($sm) {
            position: absolute;
        }
    }
    .wcm-emed-home .top-bottom-section .bg-container .hero-image--full {
        @include breakpoint($xs) {
            height: 200px;
        }
    }
}
