.brand__lockup img {
    height: 84px;
}

.title-bar {
    font-family: $wcm-regular;
    padding-top: 5px;
    @include breakpoint($xs) {
        font-size: 28px;
    }
}

.block-menu-block {
    @include breakpoint($md) {
        -moz-column-count: 3;
        -webkit-column-count: 3;
        -ms-column-count: 3;
        -o-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        -ms-column-gap: 15px;
        -o-column-gap: 15px;
        column-gap: 15px;
        margin-bottom: 1em;
    }
}

#block-menu-block-2 {
    max-width: 1140px;
    padding: 0 1em;

    margin: 25px 20px 25px;
    font-size: 12px;
    line-height: 28px;
    position: relative;
    border: 1px solid $wcm-border-gray;
    border-radius: 10px;
    color: $wcm-med-gray;

    h2 {
        font-size: 14px;
        margin: 14px 0 10px;
        text-align: center;
        color: $wcm-dark-orange;
        cursor: pointer;

        &:after {
            content: "\e80a";
            @include fontello();
            width: 45px;
            display: block;
            position: absolute;
            top: 8px;
            right: 0;
            font-size: 20px;
        }

        &.menu-active:after {
            content: "\e809";
        }
    }

    .content {
        display: none;
        margin: 0;
        text-align: center;

        ul {
            padding: 0;
            margin: 0 0 5px 0;
        }

        a {
            color: $wcm-med-gray;
        }
    }

    @include breakpoint($sm) {
        display: none;
    }
}

#primary-nav .level-1 {
    width: 20%;
}

.information-sidebar {
    .pane-bundle-text {
        word-break: break-all;
    }
}

/* =Default Select Elements
----------------------------------------------------------*/

.form-select {
    max-width: none;
    height: 50px;
    border: 1px solid $wcm-border-gray;
    box-shadow: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 15px top 50%;
    background-size: 20px 20px;
    padding-right: 50px;
  }

/** Homepage **/
/*.front {
    .pane-news-panel-pane-3 {
        margin-bottom: 30px;
        .view-teaser {
          @include clearfix();
          border-bottom: 1px solid $wcm-border-gray;
          padding-bottom: 20px;

          .teaser-image img.panopoly-image-quarter {
              float: none;
              margin: 0 0 0.25em 0;
              width: auto;
              max-width: none;
              @include breakpoint($sm) {
                  float: right;
                  margin: 0.25em 0 1.3em 1em;
                  width: 25%;
                  max-width: 25%;
              }
          }
        }
    }
}*/
/**************/
.page-node-217 {
  .hero-image--full {
    background-position: top center;
  }
}
.vept-sidebar {
    border: 2px solid $wcm-border-gray;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
}
.not-front.logged-in .wcm-emed-vept .middle-section-full .panels-ipe-sort-container {
  /* two items */
  .panels-ipe-portlet-wrapper {
    @include breakpoint($sm) {
      width: 50%;
      float: left;
    }
  }
}

.not-front.logged-in .wcm-emed-vept .bottom-callouts {
  .col-sm-12 {
    display: block;
  }
  .panels-ipe-sort-container {
    .panels-ipe-portlet-wrapper {
      @include breakpoint($sm) {
        width: 33.333%;
        float: left;
      }
    }
  }
}

.wcm-emed-vept {
  .hero-image--full {
    width: 100%;
    height: 175px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin: 0 0 20px;

    @include breakpoint($sm) {
      height: 300px;
    }
  }

  .middle-section {
    margin-top: 30px;
    margin-bottom: 30px;

    .pane-bundle-text {
      .pane-title {
        color: $wcm-med-gray;
        border-bottom: 2px solid $wcm-border-gray;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .middle-section-full {
    margin-top: 20px;
    margin-bottom: 20px;
    .col-sm-12 {
      @include breakpoint($sm) {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 0px;
      }

      .pane-bundle-wcm-emed-callout {
        position: relative;
        &:after {
          content: '';
          background-color: $wcm-bg-gray;
          position: absolute;
          width: calc(100% - 40px);
          height: 100%;
          top: 0;
          z-index: -1;
        }
        @include breakpoint($sm) {
          padding-left: 20px;
          padding-right: 20px;
        }

        .wcm-callout-wrapper {
          @include breakpoint($sm) {
            padding: 20px;
          }
        }
      }

      /* one item */
      // .pane-bundle-wcm-emed-callout:first-child:nth-last-child(1) {
      //   @include breakpoint($sm) {
      //     width: 50%;
      //   }
      // }

      /* two items */
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(2),
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(2) ~ div {
        @include breakpoint($sm) {
          width: 50%;
        }
      }

      /* three items */
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(3),
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(3) ~ div {
        @include breakpoint($sm) {
          width: 33.3333%;

          .field-callout-image-wrapper {
            padding: 0;
          }
        }
      }

      /* four items */
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(4),
      .pane-bundle-wcm-emed-callout:first-child:nth-last-child(4) ~ div {
        @include breakpoint($sm) {
          width: 50%;

          .field-callout-image-wrapper {
            padding: 0;
          }
        }
        @include breakpoint($md) {
          width: 25%;
        }
      }
    }
  }

  .bottom-container {
    .pane-bundle-text {
      .pane-title {
        color: $wcm-med-gray;
        border-bottom: 2px solid $wcm-border-gray;
        padding-bottom: 10px;
        margin-bottom: 20px;
      }
    }
  }

  .bottom-callouts {
    margin-top: 30px;
    margin-bottom: 10px;
    background: url(../images/vept-background-landing.jpg) no-repeat top center;
    background-size: cover;
    padding-top: 50px;
    padding-bottom: 50px;

    .col-sm-12 {
      @include breakpoint($sm) {
        display: flex;
        flex-wrap: wrap;
        padding-left: 0px;
        padding-right: 0px;
      }

      .pane-bundle-text {
        position: relative;
        text-align: center;
        a {
          // color: $wcm-white;
          display: block;
          background-color: $wcm-white;
          border-bottom: none;
          padding: 25px 10px 20px;
          font-weight: bold;

          &:before {
            content: '';
            background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/email.svg);
            background-repeat: no-repeat;
            background-size: 50px 50px;
            text-align: center;
            display: block;
            height: 50px;
            width: 50px;
            position: relative;
            margin: 0 auto 10px;
            transition: all 0.3s ease 0s;
          }

          &:hover {
            &:before {
              transform: scale(1.1);
            }
          }
        }

        &.callout-funding a:before {
          background-image: url(../images/icon-funding.svg);
        }
        &.callout-news a:before {
          background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/news.svg);
        }
        &.callout-contact a:before {
          background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/email.svg);
        }
        @include breakpoint($sm) {
          padding-left: 20px;
          padding-right: 20px;
        }

      }

      /* three items */
      .pane-bundle-text:first-child:nth-last-child(3),
      .pane-bundle-text:first-child:nth-last-child(3) ~ div {
        @include breakpoint($sm) {
          width: 33.3333%;
        }
      }

    }
  }

  #footer-callouts {
    text-align: center;
    padding: 20px;

    .pane-bundle-text {
      max-width: 600px;
      margin: 0 auto;
    }

    h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
    }

    p a {
      padding: 5px 0px 5px 35px;
      background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/give.svg);
      background-repeat: no-repeat;
      background-size: 25px 25px;
      background-position: left center;
    }
  } // footer callouts
}

.about-us-callouts {
    @include clearfix();
    a {
        font-family: $wcm-bold;
        font-size: 20px;
        background-color: $wcm-dark-orange;
        color: $wcm-white;
        text-align: center;
        position: relative;

        width: 100%;
        display: block;
        padding: 20px 10px;
        margin-bottom: 10px;

        @include breakpoint($sm) {
            width: 33.3%;
            float: left;
            padding: 60px 10px;
            border-left: 5px solid $wcm-white;
            border-right: 5px solid $wcm-white;
        }

        &:after {
            content: '\e80d';
            color: $wcm-white;
            @include fontello();
            font-size: 60%;
            padding-left: 10px;
        }

        &:before {
            content: '';
            transition: all 0.3s ease 0s;
            border-bottom: 2px solid $wcm-white;
            position: absolute;
            display: block;
            padding-top: 35px;
            width: 0;
            margin: 0 auto;
            left: 0;
            right: 0;
        }

        &:hover {
            &:before {
                width: 70%;
            }
        }
    }
}

.page-news-events {
    .panel-twocol h1 { margin-bottom: 20px; }
}

.node-type-news-post {
    .pane-node-field-source-link {
      .field-label {
        float: left;
      }
    }

    .pane-node-field-featured-image {
        img {
            width: auto;
        }
    }
  }

.pane-news-panel-pane-1, .pane-news-panel-pane-3,
.pane-news-panel-pane-5, .pane-news-panel-pane-6,
.pane-news-panel-pane-7, .pane-news-panel-pane-8  {
    .pane-title {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .view-teaser {
      @include clearfix();
      border-bottom: 1px solid $wcm-border-gray;
      padding-bottom: 20px;

      .post-date {
        color: $wcm-med-gray;
        font-family: $wcm-bold;
        text-transform: uppercase;
        margin-top: 10px;
        font-size: 13px;
        letter-spacing: 2px;
      }

      .views-field-field-news-category {
        clear: both;
        padding: 10px 0;
        a {
            border-bottom: none;
            position: relative;
            padding-left: 25px;
            color: $wcm-med-gray;
            font-size: 14px;

            &:before {
                content: '';
                background: url(../images/tag.svg);
                background-size: 20px 20px;
                height: 20px;
                width: 20px;
                position: absolute;
                left: 0;
            }
        }
    }

    .teaser-image img.panopoly-image-quarter {
        float: none;
        @include breakpoint($sm) {
            margin-right: 20px;
            float: left;
        }
      }
      /*.teaser-image img.panopoly-image-quarter {
          float: none;
          margin: 0 0 0.25em 0;
          width: auto;
          max-width: none;
          @include breakpoint($sm) {
              float: right;
              margin: 0.25em 0 1.3em 1em;
              width: 25%;
              max-width: 25%;
          }
      }*/
    }
}

.pane-news-panel-pane-5, .pane-news-panel-pane-6, .pane-news-panel-pane-7, .pane-news-panel-pane-8 {
    border-top: 1px solid $wcm-border-gray;
    margin-top: 40px;

    .pane-title {
        font-size: 30px;
        margin-bottom: 0;
    }

    .view-header {
        text-align: right;
        font-size: 14px;
    }
}

.pane-events-panel-pane-1 {
    .pane-title {
        font-size: 30px;
        margin-bottom: 20px;
    }
}
.view-events {
    .views-row {
      @include clearfix();
      // border-bottom: 1px solid $wcm-border-gray;
      margin-bottom: 20px;
      background: $wcm-bg-gray;
      padding: 25px;

      .views-field-nothing {
          float: left;
          width: 50px;
          color: $wcm-dark-gray;
          margin-right: 20px;
          .event-date {
            .event-month {
                font-size: 20px;
                display: block;
            }
            .event-day {
                font-size: 30px;
                display: block;
            }
          }
      }

      .views-field-nothing-1 {
        font-weight: bold;
        font-size: 14px;

        .event-location {
            padding-left: 5px;
            &:before {
                content: '|';
                padding-right: 5px;
            }
        }
      }

      .views-field-field-event-image {
        img {
          float: none;
          max-width: none;
          width: auto;
          margin-right: .5em;
          margin-bottom: .25em;
          margin-top: .25em;

          @include breakpoint($sm) {
            float: right;
            width: 25%;
            max-width: 25%;
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }
}

// profiles
.view-bio-btn, .view-profile-btn {
    float: left;
    margin: 5px 30px 5px 0px;
}

.views-field-field-biographical-info {
    display: none;
    clear: both;
    padding-top: 10px;
}

.view-profiles {
  h3 {
    margin-bottom: 10px;
    font-size: 30px;
  }
  .views-row {
    @include clearfix();
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $wcm-border-gray;

    .professional-title {
      font-style: italic;
    }

    .profile-links {
      padding-top: 10px;
    }

    .views-field-field-educational-background {
        margin-bottom: 10px;
    }

    &.views-row-last {
        margin-bottom: 40px;
    }

    .col-md-3, .col-md-2 {
      padding-left: 0;
      @include breakpoint($lg) {
        width: 22%;
      }
    }
    .col-md-9, .col-md-12, .col-md-10 {
      padding-left: 0;
      padding-right: 0;
    }
    .col-md-9 {
      padding-bottom: 10px;
    }
  }
    .display-name {
        // font-size: 20px;
        color: $wcm-red;
        font-weight: bold;
        margin-bottom: 0;
        @include breakpoint($md) {
            margin-top: 0;
        }
    }
}

.pane-profiles-panel-pane-6, .pane-profiles-panel-pane-7 {

    border-top: 1px solid $wcm-border-gray;

    .view-header {
        margin-bottom: 20px;
    }

    .view-content {
        @include breakpoint($sm) {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }

        .views-row {

          .col-md-3, .col-md-2 {
            padding-left: 0;
            @include breakpoint($lg) {
              width: 25%;
            }
          }

          &.views-row-last {
            margin-bottom: 20px;
          }
            // display: -ms-flexbox;
            // display: flex;
            // -ms-flex-direction: column;
            // flex-direction: column;

            // div {
            //     display: inline;
            // }
        }
    }
}

.virtual-care-chart-1 {
    clear: both;
    @include clearfix();
    br {
        display: none;
    }
    .field-basic-text-text div {
        padding: 10px;
        background-color: $wcm-red;
        margin-bottom: 15px;
        @include breakpoint($sm) {
            float: left;
            width: 25%;
            padding: 0;
            padding-bottom: 25%;
            background-color: transparent;
        }
        color: $wcm-white;
        position: relative;
        text-align: center;
        font-weight: 600;

        span {
            @include breakpoint($sm) {
                position:absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                margin:0;
                width: 85%;
            }
        }
        &:before {
            @include breakpoint($sm) {
                content: '';
                background-color: $wcm-red;
                border: 10px solid $wcm-white;
                border-radius: 50%;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
        &:nth-child(even) {
            @include breakpoint($xs) {
                background-color: $wcm-dark-orange;
            }
            &:before {
                background-color: $wcm-dark-orange;
            }
        }
    }
}

.virtual-care-chart-2 {
    clear: both;
    position: relative;
    @include breakpoint($sm) {
        width: 65%;
        height: 525px;
        float: left;
        margin-right: 20px;
    }
    @include clearfix();
    br {
        display: none;
    }

    .field-basic-text-text div {
        &.chart-header {
            color: $wcm-black;
            border: 5px solid $wcm-med-gray;
            @include breakpoint($sm) {
                border-radius: 50%;
                width: 35%;
                padding: 0;
                padding-bottom: 35%;
                color: $wcm-black;
                position: absolute;
                top:50%;
                left:50%;
                border: none;
                transform: translate(-50%, -50%);
                z-index: 1;
                &:before {
                    border: 5px solid $wcm-med-gray;
                    background-color: $wcm-white;
                }
            }
        }

        color: $wcm-white;
        position: relative;
        text-align: center;
        font-weight: 600;
        padding: 10px;
        margin-bottom: 15px;

        @include breakpoint($sm) {
            width: 27%;
            padding: 0;
            padding-bottom: 27%;
            margin-bottom: 0;
        }

        &:nth-child(2) {
            background: $wcm-red;
            @include breakpoint($sm) {
                background: none;
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0%);

                &:after {
                    content: '';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    top: 100%;
                    background-color: $wcm-med-gray;
                    left: 49%;
                    z-index: -1;
                }
            }
        }
        &:nth-child(3) {
            background: $wcm-dark-orange;
            @include breakpoint($sm) {
                background: none;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0%, -50%);

                &:before {
                    background-color: $wcm-dark-orange;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    top: 50%;
                    background-color: $wcm-med-gray;
                    right: 40px;
                    z-index: -1;
                }
            }
        }
        &:nth-child(4) {
            background: $wcm-red;
            @include breakpoint($sm) {
                background: none;
                position: absolute;
                left:50%;
                bottom: 0;
                transform: translate(-50%, 0%);

                &:after {
                    content: '';
                    width: 5px;
                    height: 100%;
                    position: absolute;
                    bottom: 100%;
                    background-color: $wcm-med-gray;
                    left: 49%;
                    z-index: -1;
                }
            }
        }
        &:nth-child(5) {
            background: $wcm-dark-orange;
            @include breakpoint($sm) {
                background: none;
                position: absolute;
                top:50%;
                transform: translate(0%, -50%);

                &:before {
                    background-color: $wcm-dark-orange;
                }

                &:after {
                    content: '';
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    top: 50%;
                    background-color: $wcm-med-gray;
                    left: 49%;
                    z-index: -1;
                }
            }
        }

        span {
            @include breakpoint($sm) {
                position:absolute;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                margin:0;
                width: 85%;
            }
        }

        &:before {
            @include breakpoint($sm) {
                content: '';
                background-color: $wcm-red;
                // border: 10px solid $wcm-white;
                border-radius: 50%;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }
    }
}

.virtual-care-chart-3 {
    @include clearfix();
    clear: both;
    background-color: $wcm-bg-gray;
    position: relative;
    padding: 20px;

    .field-basic-text-text {
        .chart-wrapper {
            @include breakpoint($xs) {
                margin-bottom: 10px;
            }
            @include breakpoint($sm) {
                width: 33.3%;
                float: left;
            }
            position: relative;

            &:nth-child(1) {
                z-index: 2;
            }
            &:nth-child(2) {
                z-index: 1;
                .chart-header {
                    background-color: $wcm-dark-orange;
                    &:after {
                        border-color: transparent transparent transparent $wcm-dark-orange;
                    }
                }
            }
        }
        .chart-header {
            background: $wcm-red;
            padding-top: 20px;
            padding-bottom: 20px;
            @include breakpoint($sm) {
                width: 81%;
                height: 140px;
                padding-top: 40px;
                padding-left: 50px;
                padding-bottom: 0;
            }
            color: $wcm-white;
            text-align: center;
            position: relative;
            font-weight: bold;

            span {
                display: block;
                font-family: $wcm-bold;
                font-size: 38px;
                line-height: 30px;
            }

            @include breakpoint($sm) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 70px 0 70px 50px;
                    border-color: transparent transparent transparent $wcm-bg-gray;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -50px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 70px 0 70px 50px;
                    border-color: transparent transparent transparent $wcm-red;
                }
            }
        }

        .chart-content {
            position: relative;
            padding: 5px 10px 10px;
        }
    }
}

.program-contact {
    border: 2px solid $wcm-border-gray;
    padding: 20px;
    margin-bottom: 20px;

    h3 {
      &:before {
        content: '';
        background-image: url(/profiles/wcmc/themes/wcm_brand_base/images/cta_icons/SVG/email.svg);
        background-repeat: no-repeat;
        background-size: 35px 35px;
        text-align: center;
        display: block;
        height: 35px;
        width: 35px;
        position: relative;
        margin: 0 auto 10px;
      }

      text-align: center;
      margin-top: 0;
      color: $wcm-med-gray;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 14px;
      margin-bottom: 20px;
      display: block;
      text-transform: uppercase;
      padding-bottom: 10px;;
      border-bottom: 1px solid $wcm-border-gray;
    }
}

// Research Initiatives
.view-research-initiatives {
    padding-top: 20px;

    .views-row {
        margin-bottom: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $wcm-border-gray;

        .views-field-field-research-initiative-cat {
            font-size: 13px;
            color: $wcm-dark-gray;
            background: url(../images/tag.svg);
            background-size: 20px 20px;
            background-repeat: no-repeat;
            background-position: center left;
            padding-left: 25px;
        }
    }
}

/*******************/
// Divisions
.view-divisions {

    .view-header {
        padding-bottom: 10px;
    }

    .views-row {
        background: $wcm-bg-gray;
        padding: 20px 40px 40px;
        margin-bottom: 20px;

        &:hover {
            .views-field-field-division-featured-image img {
                transform: scale(1.2);
            }
            .views-field-title h3:after {
                border-bottom: 2px solid $wcm-red;
            }
        }
        .views-field-title {
            text-align: center;
            margin-bottom: 20px;
            h3 {
                &:after {
                    content: '';
                    border-bottom: 2px solid $wcm-border-gray;
                    position: relative;
                    width: 25%;
                    display: block;
                    margin: 0 auto;
                    padding-top: 10px;
                    transition: all 0.3s ease 0s;
                }
            }
        }

        .views-field-field-division-featured-image {
            text-align: center;
            @include breakpoint($sm) {
                padding-left: 40px;
                padding-right: 40px;
            }
            padding-bottom: 20px;

            img {
                transition: all 0.3s ease 0s;
            }

            .field-content {
                overflow: hidden;
            }
        }
    }
}

.node-type-division {
    .pane-node-field-division-mission-statement {
        font-size: 20px;
    }
    .our-services-anchor {
        margin-bottom: 30px;
    }

    .field-collection-container {
        border: none;
    }

    .pane-node-field-division-chair-letter {
        border-top: 1px solid $wcm-border-gray;
        padding-top: 10px;
    }

    .pane-node-field-division-our-services {
      clear: both;
      border-top: 1px solid $wcm-border-gray;
      margin-top: 30px;
    }

    .pane-node-field-division-education-program {
      margin: 40px 0;

      .pane-title {
        margin-bottom: 20px;
      }

      ul {
        li {
          padding-bottom: 15px;
        }
      }
    }

    .pane-node-field-division-link {
        margin: 30px 0;
        a {
            background: $wcm-dark-orange;
            display: block;
            color: $wcm-white;
            text-align: center;
            padding: 25px 0;
            position: relative;

            &:after {
                display: none;
            }

            .cta-icon {
                width: 70px;
                height: 70px;
                @include breakpoint($sm) {
                    border-left: 1px solid $wcm-white;
                    float: right;
                    width: 150px;
                    height: 140px;
                    padding: 40px;
                }
                color: $wcm-white;
                fill: $wcm-white;
            }

            span {
                &.division-callout-title {
                    font-family: $wcm-bold;
                    font-size: 30px;
                    display: block;
                    @include breakpoint($sm) {
                        padding-top: 30px;
                        display: inline-block;
                    }
                    position: relative;

                    &:after {
                        content: '';
                        transition: all 0.3s ease 0s;
                        border-bottom: 2px solid $wcm-white;
                        position: absolute;
                        display: block;
                        // padding-top: -5px;
                        width: 0;
                        margin: -5px auto 0 auto;
                        // margin-left: calc(100%/2 - 170px);
                        left: 0;
                        right: 0;
                    }
                }
                &:last-child {
                    @include breakpoint($sm) {
                        padding-bottom: 30px;
                    }
                }
                display: block;
            }

            &:hover {
                span {
                    &.division-callout-title {
                        &:after {
                            width: 190px;
                            margin: -5px auto 0 auto;
                            // margin-left: calc(100%/2 - 170px);
                            /*@include breakpoint($sm){
                                margin: -5px auto 0 190px;
                            }
                            @include breakpoint($md){
                                margin: -5px auto 0 178px;
                            }
                            @include breakpoint($lg){
                                margin: -5px auto 0 252px;
                            }*/
                        }
                    }
                }
            }
        }
    }
}

.pane-bundle-wcm-emed-callout {

  margin-bottom: 20px;

  .wcm-callout-wrapper {
    background: $wcm-bg-gray;
    padding: 20px 40px 40px;

    &:hover {
      .wcm-callout__headline:after {
          border-bottom: 2px solid $wcm-red;
      }
      .field-callout-image img {
          transform: scale(1.2);
      }
    }
    .wcm-callout__headline {
      text-align: center;
      margin-bottom: 20px;
      margin-top: 0;

      &:after {
        content: '';
        border-bottom: 2px solid $wcm-border-gray;
        position: relative;
        width: 25%;
        display: block;
        margin: 0 auto;
        padding-top: 10px;
        transition: all 0.3s ease 0s;
      }
    }

    .field-callout-image-wrapper {
      @include breakpoint($sm) {
        padding-left: 60px;
        padding-right: 60px;
      }
      padding-bottom: 20px;

      .field-callout-image {
        overflow: hidden;
        text-align: center;

        img {
          transition: all 0.3s ease 0s;
        }
      }
    }
  }
}

/*******************/
// Education Services
.view-services {
  margin-top: 40px;

  .views-row {
    background: $wcm-bg-gray;
    padding: 20px 40px 40px;
    margin-bottom: 20px;

    &:hover {
      .views-field-field-service-featured-image img {
        transform: scale(1.2);
      }
      .views-field-title h3:after {
        border-bottom: 2px solid $wcm-red;
      }
    }

    .views-field-title {
      text-align: center;
      margin-bottom: 20px;
      h3 {
        &:after {
          content: '';
          border-bottom: 2px solid $wcm-border-gray;
          position: relative;
          width: 25%;
          display: block;
          margin: 0 auto;
          padding-top: 10px;
          transition: all 0.3s ease 0s;
        }
      }
    }

    .views-field-field-service-featured-image {
      text-align: center;
      @include breakpoint($sm) {
        padding-left: 40px;
        padding-right: 40px;
      }
      padding-bottom: 20px;

      img {
        transition: all 0.3s ease 0s;
      }

      .field-content {
        overflow: hidden;
      }
    }
  }
}

/*******************/
// Education Programs
.view-education-programs {

    margin-top: 40px;

    .views-row {
        background: $wcm-bg-gray;
        padding: 20px 40px 40px;
        margin-bottom: 20px;

        &:hover {
            .views-field-field-education-featured-image img {
                transform: scale(1.2);
            }
            .views-field-title h3:after {
                border-bottom: 2px solid $wcm-red;
            }
        }

        .views-field-title {
            text-align: center;
            margin-bottom: 20px;
            h3 {
                &:after {
                    content: '';
                    border-bottom: 2px solid $wcm-border-gray;
                    position: relative;
                    width: 25%;
                    display: block;
                    margin: 0 auto;
                    padding-top: 10px;
                }
            }
        }

        .views-field-field-education-featured-image {
            text-align: center;
            @include breakpoint($sm) {
                padding-left: 40px;
                padding-right: 40px;
            }
            padding-bottom: 20px;

            img {
                transition: all 0.3s ease 0s;
            }

            .field-content {
                overflow: hidden;
            }
        }
    }
}

.node-type-education-program {

    .pane-node-body {
        font-size: 20px;
        margin-bottom: 40px;
    }

    .pane-node-field-education-additional-info {
        clear: both;
        margin-top: 30px;
        margin-bottom: 20px;

        .field-collection-container {
            border: none;
        }
    }

    .pane-node-field-education-contact-form {
        margin-bottom: 30px;
        .field-items {
            text-align: center;
            a {
                display: inline-block;
                margin-bottom: 10px;

                &:after {
                    display: none;
                }
            }
        }
        .cta-icon {
            width: 40px;
            height: 40px;
            fill: $wcm-bright-orange;
            color: $wcm-dark-orange;
            display: block;
            margin: 0 auto 5px;
        }
    }
}

/*** Publications ***/
.view-publications {
  h3 {
    color: $wcm-black;
    background-color: $wcm-bg-gray;
    border: 1px solid $wcm-border-gray;
    padding: 10px 15px 5px;
    margin: 20px 0;
  }

  .publication-teaser {
    margin: 0 0 10px;
  }
}
